// Main.js
import React from 'react';
import './styles/main.css';

const Main = () => {
  return (
    <main>
      <section id="home" className="hero">
        <h2>Welcome to the my Website!</h2>
        <p>This is for test purposes and it will be updated shortly.</p>
      </section>
      <section id="about">
        <h2>About Us</h2>
        <p>I am a Devops Engineer who is passionate about creating technology. I aim to improve myself by tryin best practices for maintaining this website.</p>
      </section>
      <section id="contact">
        <h2>Contact Us</h2>
        <p>If you have any questions or suggestions, feel free to get in touch with me.</p>
      </section>
    </main>
  );
};

export default Main;
