// Footer.js
import React from 'react';
import './styles/footer.css';

const Footer = () => {
  return (
    <footer>
      <p>© 2023 Gokalp Meric. All rights reserved.</p>
      <div className="social-links">
        <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">Twitter</a>
        <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">Facebook</a>
        <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer">LinkedIn</a>
      </div>
    </footer>
  );
};

export default Footer;
